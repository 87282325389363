import Settings from './containers/Settings';
import { Role, RouteConfig } from '../../types';

const AllRoles = Object.values(Role);

const routes: RouteConfig[] = [
  {
    key: 'settings',
    path: '/settings',
    title: 'Settings',
    component: Settings,
    roles: AllRoles,
  },
];

export default routes;
