import EventsList from './containers/EventsList';
import { Role, RouteConfig } from '../../types';

const AllRoles = Object.values(Role);

const routes: RouteConfig[] = [
  {
    key: 'events',
    path: '/events',
    title: 'Events',
    component: EventsList,
    roles: AllRoles,
  },
];

export default routes;
