import { FunctionComponent, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { getDcaProfile, getUser } from '../apis';
import { useAuthContext } from '../contexts';
import datadog from '../utils/datadog';

const Initializer: FunctionComponent = () => {
  const { user, profile, setUser, setProfile } = useAuthContext();
  const { authState } = useOktaAuth();

  useEffect(() => {
    (async () => {
      if (authState.isAuthenticated) {
        if (!user) {
          const newUser = await getUser();
          if (newUser) {
            setUser(newUser);
            if (!datadog.hasUser()) {
              datadog.setUser(newUser);
            }
          }
        }
        if (!profile) {
          const newProfile = await getDcaProfile();
          if (newProfile) {
            setProfile(newProfile);
          }
        }
      }
    })();
  }, [authState.isAuthenticated]);

  return null;
};

export default Initializer;
