import Dashboard from './containers/Dashboard';
import { Role, RouteConfig } from '../../types';

const AllRoles = Object.values(Role);

const routes: RouteConfig[] = [
  {
    key: 'login',
    path: '/dashboard',
    title: 'Dashboard',
    component: Dashboard,
    roles: AllRoles,
  },
];

export default routes;
