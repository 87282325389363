import { Toast, ThemeProvider, dcaTheme } from 'portal-commons';
import Favicon from 'react-favicon';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import AppWithRouterAccess from './AppWithRouterAccess';
import { S3_ASSETS_PATH } from './constants';
import { AuthContextProvider } from './contexts';
import LoadingModal from './shared_elements/LoadingModal';
import configureStore from './store/configureStore';
import datadog from './utils/datadog';
import { restoreUser } from './utils/storage';

import './assets/styles/_app.scss';

const store = configureStore();

datadog.init();
const user = restoreUser();
if (user && !datadog.hasUser()) {
  datadog.setUser(user);
}

const App = () => {
  return (
    <div className="App" data-testid="app">
      <ThemeProvider theme={dcaTheme}>
        <Favicon url={`${S3_ASSETS_PATH}/images/favicon-48x48.ico`} />
        <Provider store={store}>
          <AuthContextProvider>
            <LoadingModal />
            <Toast />
            <Router>
              <AppWithRouterAccess />
            </Router>
          </AuthContextProvider>
        </Provider>
      </ThemeProvider>
    </div>
  );
};
export default App;
