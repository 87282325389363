import { Box, Table } from 'portal-commons';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-regular-svg-icons';
import { Grid, LinearProgress, withStyles } from '@material-ui/core';

import { AddNewApiKeyDialog, ApiKeyListingRow } from './index';
import { S3_ASSETS_PATH } from '../../../constants';
import {
  globalGetService,
  globalPostService,
} from '../../../utils/globalApiServices';

const TABLE_COLUMNS = [
  { id: 'label', label: 'API LABEL', sortable: false },
  { id: 'key', label: 'API KEY', sortable: false },
  { id: 'secret', label: 'SECRET', sortable: false },
  { id: 'last_updated', label: 'LAST UPDATED', sortable: false },
  { id: 'active', label: 'ACTIVE', sortable: false },
  { id: 'actions', label: 'ACTIONS', sortable: false },
];

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#fff',
  },
  barColorPrimary: {
    background: '#00AC9D',
  },
})(LinearProgress);

export default function ApiKeysListing(props) {
  const [loading, setLoading] = useState(false);
  const [apiAccounts, setApiAccounts] = useState([]);
  const [addNewApiKey, setAddNewApiKey] = useState(false);
  const [activeAccount, setActiveAccount] = useState({});
  const [isAddingNewKey, setIsAddingNewKey] = useState(false);
  const [displayAccounts, setDisplayAccounts] = useState([]);
  const [pageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);

  const fetchApiAccounts = (query = {}) => {
    setLoading(true);
    globalGetService(`dca/api/account`, query).then((response) => {
      setLoading(false);
      if (response.status >= 200 && response.status < 300) {
        setApiAccounts(response.data);
      }
    });
  };

  const handleAddNewApiKey = () => {
    setIsAddingNewKey(true);
    globalPostService(`dca/api/account`, {}).then((response) => {
      setIsAddingNewKey(false);
      if (response.status >= 200 && response.status < 300) {
        setActiveAccount(response.data);
        setAddNewApiKey(true);
        fetchApiAccounts();
      }
    });
  };

  useEffect(() => {
    fetchApiAccounts();
  }, []);

  useEffect(() => {
    const start = (currPage - 1) * pageSize;
    const end = start + pageSize;
    setDisplayAccounts(apiAccounts.slice(start, end));
  }, [apiAccounts, currPage, pageSize]);

  return (
    <>
      <div className="api-keys-listing-wrapper" data-testid="apiKeysListing">
        <div className="title-blk">
          <h3 className="heading2" data-testid="apiKeysListingAddButton">
            <Box flexDirection="row" justifyContent="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faKey} />
              </Box>
              <span>API Keys</span>
            </Box>
            <a
              onClick={handleAddNewApiKey}
              className={`primary-btn ${isAddingNewKey ? 'disabled' : ''}`}
            >
              Add New
            </a>
          </h3>
        </div>
        <Table
          testId="apiKeyListingTable"
          loading={loading}
          className="api-keys-listing-table"
          emptyState=""
          disableHover
          headRows={TABLE_COLUMNS}
          records={{ total: apiAccounts.length }}
          data={displayAccounts}
          renderRow={(data) => {
            return (
              <ApiKeyListingRow
                accountObj={data}
                key={data.id}
                fetchApiAccounts={fetchApiAccounts}
              />
            );
          }}
          handleChangePage={(page) => setCurrPage(page)}
          pagination={{
            page: currPage,
            rowsPerPage: pageSize,
            totalRecords: apiAccounts.length,
          }}
        />
      </div>
      <AddNewApiKeyDialog
        open={addNewApiKey}
        type={'add'}
        handleClose={() => {
          setAddNewApiKey(false);
          setActiveAccount({});
        }}
        account={activeAccount}
        // fetchApiAccounts={fetchApiAccounts}
      />
    </>
  );
}
