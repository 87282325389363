import { PermissionProvider } from 'portal-commons';
import { useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, SecureRoute } from '@okta/okta-react';

import PageNotFound from './404';
import CustomLoginCallback from './CustomLoginCallback';
import { Initializer, PrivateRoute } from './components';
import { OKTA_CLIENT_ID, OKTA_ISSUER_URL } from './constants';
import Permissions from './constants/permissions';
import { useAuthContext } from './contexts';
import { authRoutes } from './features/auth/authRoutes';
import campaignsRoutes from './features/campaigns/routes';
import dashboardRoutes from './features/dashboard/routes';
import eventsRoutes from './features/events/routes';
import integrationRoutes from './features/integrations/routes';
import settingsRoutes from './features/settings/routes';
import usersRoutes from './features/users/routes';

const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER_URL,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  pkce: false,
  postLogoutRedirectUri: window.location.origin + '/login',
});

const AppWithRouterAccess = () => {
  const { role } = useAuthContext();
  const history = useHistory();
  const location = useLocation();

  const onAuthRequired = () => {
    history.push('/login');
  };

  const computeViewName = (routeMatches) => {
    let viewName = '';
    for (let index = 0; index < routeMatches.length; index++) {
      const routeMatch = routeMatches[index];
      const { path } = routeMatch;

      if (!path) {
        return null;
      }

      if (path.startsWith('/')) {
        viewName = path;
      } else {
        viewName += viewName.endsWith('/') ? path : `/${path}`;
      }
    }

    return viewName || '/';
  };

  useEffect(() => {
    const allRoutes = [
      ...authRoutes,
      ...dashboardRoutes,
      ...campaignsRoutes,
      ...settingsRoutes,
      ...integrationRoutes,
      ...usersRoutes,
      ...eventsRoutes,
    ];
    const routeMatches = allRoutes.filter((route) => {
      const regex = new RegExp(route.path.replace(/:[^\/]+/g, '\\w+'));
      return regex.test(location.pathname);
    });
    const viewName = routeMatches && computeViewName(routeMatches);
    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [location.pathname]);

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired}>
      <PermissionProvider permissions={Permissions} role={role}>
        <Initializer />
        <Switch>
          <Route
            key="callback"
            exact
            path="/implicit/callback"
            component={CustomLoginCallback}
          />
          {authRoutes.map(({ path, component, key }) => (
            <Route exact path={path} component={component} key={key} />
          ))}
          {[
            ...dashboardRoutes,
            ...campaignsRoutes,
            ...settingsRoutes,
            ...eventsRoutes,
            ...integrationRoutes,
            ...usersRoutes,
          ].map((config) => (
            <PrivateRoute
              key={config.key}
              exact
              path={config.path}
              title={config.title}
              note={config.note}
              component={config.component}
              roles={config.roles}
            />
          ))}
          <Route path="/404" component={PageNotFound} key="404" />
          <Redirect from="*" to="/404" />
        </Switch>
      </PermissionProvider>
    </Security>
  );
};
export default AppWithRouterAccess;
