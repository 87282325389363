import React, { useState, useEffect } from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { AutomatedActionBtn, WarningDialog } from './index';
import { S3_ASSETS_PATH } from '../../../constants';
import { convertTimeWithTimezone } from '../../../utils/time';
import { globalPutService } from '../../../utils/globalApiServices';
import { toastFlashMessage } from '../../../utils';
import { Box, RoleGuard } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

export default function ExceptionalCspsListingRow(props) {
  const { cspObj } = props;
  const [action, setAction] = useState('');
  const [enableSaveBtn, setEnableSaveBtn] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const [csp, setCsp] = useState(cspObj);

  const updateAction = () => {
    setOpenWarning(false);
    const formData = {
      action,
      cspUid: csp.cspUid,
      // enabled:
    };
    updateAutomatedAction(formData);
  };
  const updateAutomatedAction = (data = {}) => {
    setActionLoader(true);
    globalPutService(
      `dca/primaryDcaCspRule?action=${data.action}&cspUid=${data.cspUid}`,
      {}
    ).then((response) => {
      setActionLoader(false);
      if (response.status >= 200 && response.status < 300) {
        toastFlashMessage('Automated action updated successfully', 'success');
        setCsp(response.data);
        setAction(response.data.action);
        setEnableSaveBtn(false);
      }
    });
  };

  const handleChangeAction = (option) => {
    if (csp.action !== option) {
      setAction(option);
      setEnableSaveBtn(true);
    } else {
      setAction(option);
      setEnableSaveBtn(false);
    }
  };
  useEffect(() => {
    setCsp(cspObj);
    setAction(cspObj.action);
    setEnableSaveBtn(false);
  }, [cspObj]);
  return (
    <>
      <TableRow data-testid="exceptionalCspsListingRow">
        <TableCell data-testid="exceptionalCspsListingRowCspNameOrCspUid">
          {csp.cspName || csp.cspUid}
        </TableCell>
        <TableCell style={{ padding: 8 }}>
          <RoleGuard
            feature="settings.listExceptionActions"
            render={(hasPermission) => {
              return (
                <AutomatedActionBtn
                  action={action}
                  exception={true}
                  handleChange={handleChangeAction}
                  disabled={!hasPermission}
                />
              );
            }}
          />
        </TableCell>
        <TableCell>
          <RoleGuard
            feature="settings.saveException"
            render={(hasPermission) => {
              return (
                <a
                  className={`primary-btn ${
                    actionLoader || !enableSaveBtn || !hasPermission
                      ? 'disabled'
                      : ''
                  }`}
                  onClick={() => {
                    if (action !== 'REVIEW') {
                      setOpenWarning(true);
                    } else {
                      updateAction();
                    }
                  }}
                  data-testid="exceptionalCspsListingRowSaveButton"
                >
                  Save
                </a>
              );
            }}
          />
        </TableCell>
        <TableCell style={{ paddingRight: 40 }}>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box margin={{ right: 's' }}>
              <span>{convertTimeWithTimezone(csp.updateDate)}</span>
            </Box>
            <RoleGuard
              feature="settings.deleteException"
              render={(hasPermission) => {
                return (
                  <FontAwesomeIcon
                    data-testid="exceptionalCspsListingRowDeleteButton"
                    onClick={() => hasPermission && props.deleteCsp(csp.cspUid)}
                    icon={faTrash}
                    size="lg"
                    style={
                      !hasPermission
                        ? { color: '#68737a80', cursor: 'not-allowed' }
                        : { cursor: 'pointer' }
                    }
                  />
                );
              }}
            />
          </Box>
        </TableCell>
      </TableRow>
      {openWarning ? (
        <WarningDialog
          handleClose={() => setOpenWarning(false)}
          handleSubmit={updateAction}
          type={action}
        />
      ) : null}
    </>
  );
}
