import { Table, RoleGuard } from 'portal-commons';
import React, { useState, useEffect } from 'react';
import { LinearProgress, withStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { AddNewExceptionCsp } from './index';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import ExceptionalCspsListingRow from './ExceptionalCspsListingRow';
import { S3_ASSETS_PATH } from '../../../constants';
import {
  globalGetService,
  globalDeleteService,
} from '../../../utils/globalApiServices';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#fff',
  },
  barColorPrimary: {
    background: '#00AC9D',
  },
})(LinearProgress);

const headRows = [
  {
    id: 'cspName',
    label: 'EXCEPTION FOR DOWNSTREAM CNP',
    sortable: false,
  },
  {
    id: 'action',
    label: 'AUTOMATED ACTION',
    sortable: false,
  },
  {
    id: 'save',
    label: 'SAVE',
    sortable: false,
  },
  {
    id: 'updateDate',
    label: 'LAST UPDATED',
    sortable: false,
  },
];

export default function ExceptionalCsps(props) {
  const [cspInfo, setCspInfo] = useState({});
  const [loader, setLoader] = useState(true);
  const [addNewCsp, setAddNewCsp] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState('');
  const fetchListOfExceptionalCsps = (query = {}) => {
    // setLoader(true)
    setTableLoader(true);
    globalGetService(`dca/primaryDcaCspRule`, {
      ...query,
      isDefaultRule: false,
      recordsPerPage: 5,
    }).then((response) => {
      setLoader(false);
      setTableLoader(false);
      if (response.status >= 200 && response.status < 300) {
        setCspInfo(response.data);
      }
    });
  };
  const showDeleteConfirmation = (cspUid) => {
    setDeleteModal(true);
    setDeleteTarget(cspUid);
  };
  const deleteCsp = () => {
    setCspInfo({
      ...cspInfo,
      records: cspInfo.records.filter(
        (record) => record.cspUid !== deleteTarget
      ),
    });
    setDeleteModal(false);
    globalDeleteService(`dca/primaryDcaCspRule/csp/${deleteTarget}`).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          fetchListOfExceptionalCsps();
        }
      }
    );
  };
  const handleChangePage = (e, newPage) => {
    fetchListOfExceptionalCsps({ page: newPage });
  };
  useEffect(() => {
    fetchListOfExceptionalCsps();
  }, []);
  return (
    <>
      <div
        className="exceptional-csps-listing-wrapper"
        data-testid="exceptionalCsps"
      >
        <RoleGuard
          feature="settings.addException"
          render={(hasPermission) => {
            return (
              <div className="add-new-btn-wrapper">
                <p>
                  <a
                    className={`primary-btn ${hasPermission ? '' : 'disabled'}`}
                    onClick={() => setAddNewCsp(true)}
                    data-testid="exceptionalCspsAddButton"
                  >
                    Add New
                  </a>
                </p>
              </div>
            );
          }}
        />
        <Table
          testId="exceptionalCspListingRow"
          disableHover
          loading={loader}
          headRows={headRows}
          emptyState="no csps to view"
          tableData={cspInfo?.records?.map((item, index) => (
            <ExceptionalCspsListingRow
              key={index}
              cspObj={item}
              deleteCsp={showDeleteConfirmation}
            />
          ))}
        />
        <AddNewExceptionCsp
          open={addNewCsp}
          handleClose={() => setAddNewCsp(false)}
          fetchListOfExceptionalCsps={fetchListOfExceptionalCsps}
        />
      </div>
      {cspInfo.totalRecords &&
      Math.ceil(cspInfo.totalRecords / cspInfo.recordsPerPage) > 1 ? (
        <div className="pagination-wrapper">
          <Pagination
            variant="outlined"
            shape="rounded"
            className="pagination"
            count={Math.ceil(cspInfo.totalRecords / cspInfo.recordsPerPage)}
            page={cspInfo.page}
            onChange={handleChangePage}
          />
        </div>
      ) : null}
      <DeleteConfirmationDialog
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        onSubmit={deleteCsp}
      />
    </>
  );
}
