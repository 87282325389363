import { DcaProfile, User } from '../types';
import { globalGetService } from '../utils/globalApiServices';

export const getUser = async () => {
  const response = await globalGetService('user');
  if (response.ok) {
    return response.data as User;
  }
};

export const getDcaProfile = async () => {
  const response = await globalGetService('dca/dcas/me');
  if (response.ok) {
    return response.data as DcaProfile;
  }
};
