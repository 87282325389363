import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router';
import { toastFlashMessage } from './utils';
import { Loader } from './shared_elements';

const CustomLoginCallback = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const authStateReady = !authState.isPending;

  useEffect(() => {
    oktaAuth.handleLoginRedirect();
  }, [oktaAuth]);

  if (authStateReady && authState.error) {
    if (
      authState.error &&
      authState.error.errorSummary ==
        'User is not assigned to the client application.'
    ) {
      toastFlashMessage('User is not available', 'error');
    } else if (authState.error && authState.error.errorCode == 'E0000064') {
      return <Redirect to="/expired-password" />;
    } else {
      return <Redirect to="/login" />;
    }
  } else if (
    authState.isPending &&
    !authState.isAuthenticated &&
    !authState.accessToken &&
    !authState.idToken &&
    props.location.hash &&
    props.location.hash.includes('access_denied')
  ) {
    toastFlashMessage('User is not available', 'error');
    return <Redirect to="/login" />;
  }

  return <Loader hocFlag />;
};

export default CustomLoginCallback;
