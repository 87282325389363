import CampaignsByCsps from './containers/CampaignsByCsps';
import CampaignsByPrimaryDcas from './containers/CampaignsByPrimaryDcas';
import CampaignDetail from './containers/CampaignDetail';
import { Role, RouteConfig } from '../../types';

const AllRoles = Object.values(Role);

const routes: RouteConfig[] = [
  {
    key: 'csp-campaigns',
    path: '/csp-campaigns',
    title: 'Campaigns by CSPs',
    component: CampaignsByCsps,
    roles: AllRoles,
  },
  {
    key: 'dca-campaigns',
    path: '/dca-campaigns',
    title: 'Campaigns by Primary DCAs',
    note: '* Results capped at first 10,000 records',
    component: CampaignsByPrimaryDcas,
    roles: AllRoles,
  },
  {
    key: 'campaign-detail',
    path: '/campaigns/:id',
    title: 'Campaigns',
    component: CampaignDetail,
    roles: AllRoles,
  },
  {
    key: 'csp-campaign-detail',
    path: '/csp-campaigns/:id',
    title: 'Campaigns',
    component: CampaignDetail,
    roles: AllRoles,
  },
  {
    key: 'dca-campaign-detail',
    path: '/dca-campaigns/:id',
    title: 'Campaigns',
    component: CampaignDetail,
    roles: AllRoles,
  },
];

export default routes;
