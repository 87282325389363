import UsersProfile from './containers/UsersProfile';
import { Role, RouteConfig } from '../../types';

const routes: RouteConfig[] = [
  {
    key: 'profile',
    path: '/profile',
    title: 'Users',
    component: UsersProfile,
    roles: [Role.User, Role.Manager],
  },
];

export default routes;
