import React, { FC } from 'react';
import { Grid, GridSize } from '@material-ui/core';

interface Props {
  title: string;
  value?: string | JSX.Element;
  xs?: number;
}
const DetailItem: FC<Props> = ({ title, value = 'N/A', xs = 4 }) => {
  const leftXs: GridSize = xs as GridSize;
  const rightXs: GridSize = (12 - xs) as GridSize;
  const renderValue = () => {
    if (React.isValidElement(value)) return value;
    return !value ? 'N/A' : value;
  };

  return (
    <Grid
      container
      spacing={3}
      className="detail-item"
      data-testid={`detailItem-${title.replace(/\s/g, '')}`}
    >
      <Grid item xs={leftXs}>
        <h3 className="paragraph">{title}:</h3>
      </Grid>
      <Grid item xs={rightXs}>
        <h3 className="paragraph semiBold">{renderValue()}</h3>
      </Grid>
    </Grid>
  );
};

export default DetailItem;
