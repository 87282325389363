import React, { FC } from 'react';
import { Tooltip, ClickAwayListener, makeStyles } from '@material-ui/core';
import { BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  customTooltip: {
    margin: 0,
    padding: 0,
    maxWidth: 526,
    color: '#071822',
    backgroundColor: '#fff',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '3px',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
    overflow: 'hidden',
  },
});

interface Props {
  data: string[];
}

const VettingScoreTooltip: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            positionFixed: true,
            modifiers: {
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            },
          },
        }}
        id="vettingScoreTooltip"
        open={open}
        onClose={() => setOpen(false)}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement={'bottom-end'}
        classes={{
          tooltip: classes.customTooltip,
        }}
        title={
          <>
            <Box
              sx={{
                fontSize: 'H400',
                fontWeight: 700,
                backgroundColor: 't.blue100',
                color: 't.white',
                px: 'l',
                py: 's',
              }}
            >
              Please see the vetting details below:
            </Box>
            <Box sx={{ p: 'l' }}>
              <ul style={{ paddingLeft: 14 }} data-testid="vettingDetailList">
                {data.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Box>
          </>
        }
      >
        <Box
          onClick={() => setOpen(!open)}
          sx={{
            marginLeft: 3,
            backgroundColor: open ? 't.paleBlue400' : 'transparent',
            cursor: open ? 'pointer' : 'default',
            borderRadius: open ? '50%' : '0%',
            '&:hover': {
              backgroundColor: 't.paleBlue400',
              cursor: 'pointer',
              borderRadius: '50%',
            },
            '+ .MuiTooltip-popper': {
              left: '100px !important',
            },
          }}
          data-testid="vettingScoreTooltipIcon"
        >
          <FontAwesomeIcon icon={faEye} style={{ margin: 3 }} />
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default VettingScoreTooltip;
