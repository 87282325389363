import { isEqual } from 'lodash';
import { FilterType, Table, TableFilter } from 'portal-commons';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Container, Grid, Box } from '@material-ui/core';

import {
  acceptPrimaryDcaCampaign,
  fetchAllDcas,
  getAllUsecaseTypes,
  waiting,
  rejectPrimaryDcaCampaign,
} from '../apis';
import { CampaignsByPrimaryDcasListingRow } from '../components';
import CampaignsByPrimaryDcaActionDialog from '../components/CampaignsByPrimaryDcaActionDialog';
import { MAX_EVENT_RECORDS } from '../../events/constants';
import { withAuthContext } from '../../../contexts';
import { withFilters } from '../../../hocs';
import { Loader, FilterToggleButton } from '../../../shared_elements';
import { globalGetService } from '../../../utils/globalApiServices';

import '../../../assets/styles/campaigns-by-primaryDcas-module.scss';

const FILTER_CONFIGS = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Type Campaign ID',
  },
  status: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    label: 'TCR Status',
  },
  secondarySharingStatus: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Accepted', value: 'ACCEPTED' },
      { label: 'Pending', value: 'PENDING' },
    ],
    label: 'Acceptance Status',
    multiple: true,
    width: 260,
  },
  usecase: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Use Case',
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Type Brand ID',
  },
  brandUniversalEin: {
    type: FilterType.Text,
    label: 'Brand Universal EIN',
    placeholder: 'Type Brand Universal EIN',
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Type brand name',
    suggestions: [],
  },
  primaryDcaUid: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Primary DCA Name',
    multiple: true,
    width: 320,
  },
  provisional: {
    type: FilterType.Dropdown,
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
};

const headRows = [
  { id: 'campaignUid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'primaryDcaName', label: 'PRIMARY DCA', sortable: false },
  { id: 'electedMno', label: 'ELECTED FOR MNO', sortable: false },
  { id: 'usecase', label: 'USECASE', sortable: true },
  { id: 'sharedOnDate', label: 'SHARED ON', sortable: true },
  { id: 'status', label: 'TCR STATUS', sortable: false },
  {
    id: 'secondarySharingStatus',
    label: 'ACCEPTANCE STATUS',
    sortable: false,
  },
];

const fetchBrandSuggestions = (query = {}) => {
  return globalGetService('dca/brands/suggestions', {
    ...query,
    limit: 20,
  }).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      return response.data.map((b) => b.brandName);
    }
    return [];
  });
};

class CampaignsByPrimaryDcas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      campaignInfo: {},
      additionalFilters: {
        sortField: 'createDate',
        ascendingOrder: false,
      },
      tableLoader: false,
      modalOpen: false,
      modalDisabled: false,
      modalType: '',
      campaignUid: '',
      dcaNames: [],
      handlingCampaignUid: null,
      isFilterVisible: false,
    };
  }

  fetchFilterOptions = async () => {
    try {
      const { setOptions } = this.props.filter;
      const usecaseTypes = await getAllUsecaseTypes();
      const dcas = await fetchAllDcas();
      setOptions(
        'usecase',
        usecaseTypes.map((item) => ({
          label: item.displayName,
          value: item.id,
        }))
      );
      setOptions(
        'primaryDcaUid',
        dcas.map((dca) => ({
          label: dca.displayName,
          value: dca.uid,
        }))
      );
    } catch (error) {}
  };

  setColumnLoading = async (handlingCampaignUid) => {
    const tmpCampaignInfo = { ...this.state.campaignInfo };
    tmpCampaignInfo.records.forEach((record) => {
      if (record.uid === handlingCampaignUid) {
        record['onProcessing'] = true;
      }
      return record;
    });
    this.setState({ campaignInfo: tmpCampaignInfo });
    await waiting(3000);
  };

  fetchCampaignsAsSecondaryDca = async (query = {}) => {
    this.setState({ tableLoader: true });
    if (this.state.handlingCampaignUid && this.state.campaignInfo.records) {
      await this.setColumnLoading(this.state.handlingCampaignUid);
    }
    globalGetService(`dca/campaigns/secondaryDca`, { ...query }).then(
      (response) => {
        this.setState({ tableLoader: false });
        if (response.status >= 200 && response.status < 300) {
          this.setState({
            campaignInfo: response.data,
            loader: false,
            handlingCampaignUid: null,
          });
          if (
            response.data.records.length === 0 &&
            response.data.totalRecords > 0 &&
            response.data.page > 1
          ) {
            const lastPageNo = Math.ceil(response.data.totalRecords / 10);
            this.setState({
              loader: true,
            });
            this.props.history.push({
              search: `?${queryString.stringify(
                { ...query, page: lastPageNo },
                { encode: true }
              )}`,
            });
            // this.getCampaignsListApi({...query, page: lastPageNo})
          }
        }
      }
    );
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.props.history.push({
      search: `?${queryString.stringify(
        { ...additionalFilters, ...appliedFilters, page: newPage },
        { encode: true }
      )}`,
    });
  };

  handleSorting = (sortField) => {
    const { campaignInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));
    if (campaignInfo.totalRecords) {
      this.props.history.push({
        search: `?${queryString.stringify(
          {
            ...additionalFilters,
            ...appliedFilters,
            page: campaignInfo.page,
            sortField,
            ascendingOrder: !additionalFilters.ascendingOrder,
          },
          { encode: true }
        )}`,
      });
    }
  };

  handleActionClick = (type, campaignUid) => {
    this.setState({
      modalOpen: true,
      modalDisabled: false,
      modalType: type,
      campaignUid,
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      modalDisabled: false,
      modalType: '',
      campaignUid: '',
    });
  };

  handleModalSubmit = async (explanation) => {
    this.setState({
      modalDisabled: true,
      handlingCampaignUid: this.state.campaignUid,
    });
    try {
      if (this.state.modalType === 'accept') {
        await acceptPrimaryDcaCampaign(this.state.campaignUid);
      } else {
        await rejectPrimaryDcaCampaign(this.state.campaignUid, explanation);
      }
    } catch (e) {}
    this.handleModalClose();
    const params = this.parseSearchParams(this.props.location.search);
    this.fetchCampaignsAsSecondaryDca(params);
  };

  processFilterSpecialCase = (filterValues = {}) => {
    const { configs, setConfig } = this.props.filter;
    const isStatusExpired = filterValues['status'] === 'EXPIRED';
    const newValues = { ...filterValues };
    if (
      isStatusExpired &&
      filterValues['secondarySharingStatus'] !== 'ACCEPTED'
    ) {
      newValues['secondarySharingStatus'] = 'ACCEPTED';
    }
    if (Boolean(configs.secondarySharingStatus.disabled) !== isStatusExpired) {
      setConfig('secondarySharingStatus', {
        ...configs.secondarySharingStatus,
        disabled: isStatusExpired,
      });
    }

    return newValues;
  };

  replaceUrlIfMissRequiredFilter = () => {
    const { search, pathname } = this.props.location;
    const queries = queryString.parse(search, { decode: true });

    if (!['true', 'false'].includes(queries.provisional)) {
      queries.provisional = false;
      this.props.history.replace({
        pathname: pathname,
        search: queryString.stringify(queries, { encode: true }),
      });
      return true;
    }

    return false;
  };

  componentDidMount() {
    const { additionalFilters } = this.state;
    this.fetchFilterOptions();
    if (this.replaceUrlIfMissRequiredFilter()) {
      return;
    }
    if (this.props.location.search) {
      const params = this.parseSearchParams(this.props.location.search);
      const updatedFilter = this.processFilterSpecialCase(params);

      if (!isEqual(params, updatedFilter)) {
        this.writeQueryString(updatedFilter);
      } else {
        this.fetchCampaignsAsSecondaryDca(updatedFilter);
      }
    } else {
      this.fetchCampaignsAsSecondaryDca(additionalFilters);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.replaceUrlIfMissRequiredFilter()) {
      return;
    }
    // debugger
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchCampaignsAsSecondaryDca(params);
    }

    if (
      !isEqual(
        prevProps.filter.candidateFilters,
        this.props.filter.candidateFilters
      )
    ) {
      const params = this.processFilterSpecialCase(
        this.props.filter.candidateFilters
      );
      this.props.filter.handleEdit(params);
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      // console.log('pathchanged', this.props.location.pathname)
    }
  }

  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.additionalFilters;
    const { appliedFilters } = this.props.filter;

    const querys = queryString.parse(params, { decode: true });
    if (!Object.keys(querys).length) {
      updatedFilter = {
        sortField: 'createDate',
        ascendingOrder: false,
        secondarySharingStatus: 'PENDING,ACCEPTED',
      };
    } else if (querys.sortField && querys.ascendingOrder) {
      updatedFilter = {
        sortField: querys.sortField,
        ascendingOrder: JSON.parse(querys.ascendingOrder),
        secondarySharingStatus: querys.secondarySharingStatus,
      };
    }
    this.setState({
      additionalFilters: updatedFilter,
    });

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: querys.page ? querys.page : 1,
    };
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  writeQueryString = (searchParams = {}) => {
    this.props.history.push({
      search: `?${queryString.stringify(searchParams, { encode: true })}`,
    });
  };

  render() {
    const { role } = this.props;
    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
      handleEdit: handleCandidateFiltersChange,
      handleApply: handleAppliedFiltersChange,
    } = this.props.filter;
    const {
      loader,
      campaignInfo,
      additionalFilters,
      tableLoader,
      isFilterVisible,
    } = this.state;

    const maskedTotalRecords =
      campaignInfo.totalRecords > MAX_EVENT_RECORDS
        ? MAX_EVENT_RECORDS
        : campaignInfo.totalRecords;
    const maskedPaginationData = (campaignInfo.paginationRecord = {
      rowsPerPage: campaignInfo.recordsPerPage,
      page: campaignInfo.page,
      totalRecords: maskedTotalRecords,
    });

    let overLimitDisclaimer;
    if (campaignInfo.totalRecords > MAX_EVENT_RECORDS) {
      overLimitDisclaimer = (
        <div className="text-center" style={{ paddingTop: '25px' }}>
          This page can only show the latest {MAX_EVENT_RECORDS} records out of
          the total of {campaignInfo.totalRecords}. Contact support for details
          on your remaining records.
        </div>
      );
    }

    return (
      <section
        className="campaigns-by-primaryDcas-section"
        data-testid="campaignsByPrimaryDcas"
      >
        <Container
          maxWidth={false}
          className="campaigns-by-primaryDcas-container"
        >
          {loader ? (
            <Loader />
          ) : (
            <>
              <Box className="page-actions">
                <FilterToggleButton
                  visible={isFilterVisible}
                  onClick={this.toggleFilterVisibility}
                />
              </Box>
              {isFilterVisible && (
                <TableFilter
                  configs={filterConfigs}
                  candidateValues={candidateFilters}
                  appliedValues={appliedFilters}
                  onCandidateValuesChange={handleCandidateFiltersChange}
                  onAppliedValuesChange={handleAppliedFiltersChange}
                  className="primaryDcas-campaigns"
                  dataAttributes={{
                    'data-testid': 'campaignsByPrimaryDcasTableFilter',
                  }}
                />
              )}
              <Grid container justify="center" spacing={0}>
                <Table
                  testId="campaignByPrimaryDcasTable"
                  headRows={headRows}
                  emptyState="no campaigns to view"
                  records={{ total: campaignInfo.totalRecords }}
                  loading={tableLoader}
                  tableData={campaignInfo.records.map((record, index) => (
                    <CampaignsByPrimaryDcasListingRow
                      key={index}
                      campaign={record}
                      handleActionClick={this.handleActionClick}
                      role={role}
                    />
                  ))}
                  handleChangePage={this.handleChangePage}
                  createSortHandler={this.handleSorting}
                  filter={additionalFilters}
                  pagination={maskedPaginationData}
                />
                {Math.ceil(MAX_EVENT_RECORDS / campaignInfo.recordsPerPage) ===
                campaignInfo.page
                  ? overLimitDisclaimer
                  : null}
              </Grid>
            </>
          )}
        </Container>
        <CampaignsByPrimaryDcaActionDialog
          open={this.state.modalOpen}
          disabled={this.state.modalDisabled}
          type={this.state.modalType}
          onClose={this.handleModalClose}
          onSubmit={this.handleModalSubmit}
        />
      </section>
    );
  }
}

export default withFilters(withAuthContext(CampaignsByPrimaryDcas), {
  configs: FILTER_CONFIGS,
  loaders: {
    suggestion: {
      brandName: {
        minLength: 2,
        load: (val) => fetchBrandSuggestions({ prefix: val }),
      },
    },
  },
});
