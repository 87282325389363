import { PermissionConfig } from 'portal-commons';
import { Role } from '../types/user';

const Permissions: PermissionConfig = {
  profile: {
    view: { roles: [Role.Manager, Role.User] },
    editEmail: { roles: [Role.Manager] },
    editUser: { roles: [Role.Manager] },
    deleteUser: { roles: [Role.Manager] },
    addUser: { roles: [Role.Manager] },
    toggleMfa: { roles: [Role.Manager] },
  },
  integrations: {
    view: { roles: [Role.Manager, Role.User] },
  },
  campaignByCspList: {
    acceptCampaign: { roles: [Role.Manager, Role.User] },
    declineCampaign: { roles: [Role.Manager, Role.User] },
  },
  campaignByDcaList: {
    acceptCampaign: { roles: [Role.Manager, Role.User] },
    declineCampaign: { roles: [Role.Manager, Role.User] },
  },
  campaignDetail: {
    acceptConnectivityPartner: { roles: [Role.Manager, Role.User] },
    declineConnectivityPartner: { roles: [Role.Manager, Role.User] },
    suspendCarrierStatus: { roles: [Role.Manager, Role.User] },
    liftSuspensionCarrierStatus: { roles: [Role.Manager, Role.User] },
    editDcaCarrierStatus: { roles: [Role.Manager, Role.User] },
    selectDcaCarrierStatus: { roles: [Role.Manager, Role.User] },
    selectShareCarrierStatus: { roles: [Role.Manager, Role.User] },
    suspendMnoStatus: { roles: [Role.Manager, Role.User] },
    liftSuspendMnoStatus: { roles: [Role.Manager, Role.User] },
  },
  settings: {
    listDefaultActions: { roles: [Role.Manager, Role.User] },
    saveDefaultAction: { roles: [Role.Manager, Role.User] },
    addException: { roles: [Role.Manager, Role.User] },
    listExceptionActions: { roles: [Role.Manager, Role.User] },
    saveException: { roles: [Role.Manager, Role.User] },
    deleteException: { roles: [Role.Manager, Role.User] },
  },
};

export default Permissions;
