import { SideNavigation, useHasPermission } from 'portal-commons';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  faHome,
  faBullhorn,
  faCalendarDay,
  faGearCode,
  faGear,
} from '@fortawesome/pro-regular-svg-icons';
import { useAuthContext } from '../contexts';

function PrimarySidebar(props) {
  const { authenticated } = props;
  const { user, role } = useAuthContext();
  const location = useLocation();

  const navLink = useMemo(() => {
    const link = [
      {
        title: 'Dashboard',
        icon: faHome,
        pathname: '/dashboard',
      },
      {
        title: 'Campaigns by CSPs',
        icon: faBullhorn,
        pathname: '/csp-campaigns',
      },
      {
        title: 'Campaigns by Primary DCAs',
        icon: faBullhorn,
        pathname: '/dca-campaigns',
      },
      {
        title: 'Settings',
        icon: faGear,
        pathname: '/settings',
      },
      {
        title: 'Events',
        icon: faCalendarDay,
        pathname: '/events',
      },
    ];

    if (useHasPermission('integrations.view')) {
      link.push({
        title: 'Integrations',
        icon: faGearCode,
        pathname: '/integrations',
      });
    }

    return link;
  }, [role]);

  return (
    <div className={`primary-side-menu ${authenticated ? '' : 'auth-page'}`}>
      {authenticated ? (
        <SideNavigation
          portal="dca"
          items={navLink}
          location={location}
          lastLoginTime={user?.lastLogin}
          linkComponent={Link}
        />
      ) : null}
    </div>
  );
}

export default PrimarySidebar;
