import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableCell, TableRow, CircularProgress } from '@material-ui/core';
import { convertTimeWithTimezone } from '../../../utils/time';
import { remapTCRStatus } from '../utils';
import { Box, RoleGuard } from 'portal-commons';
import { getCampaignStatusIcon } from '../../../utils/statusIcon';

export default function CampaignsByPrimaryDcasListingRow(props) {
  let history = useHistory();
  let location = useLocation();
  const { campaign } = props;

  const statusIcon = (sharingStatus) => {
    if (!sharingStatus) return null;
    return (
      <h3 className="paragraph medium">
        <Box flexDirection="row" alignItems="center">
          <Box margin={{ right: 'xxs' }}>
            {getCampaignStatusIcon(campaign.sharingStatus.toLowerCase())}
          </Box>
          <span>{sharingStatus.toLowerCase()}</span>
        </Box>
      </h3>
    );
  };

  return (
    <TableRow
      data-testid="CampaignsByPrimaryDcasListingRow"
      style={{ cursor: 'pointer' }}
      onClick={() =>
        history.push({
          pathname: `/dca-campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${location.pathname}${
              location.search ? location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 70 }}
        data-testid="CampaignsByPrimaryDcasListingRowId"
      >
        <span>{campaign.uid ? campaign.uid : campaign.id}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 70 }}
        data-testid="CampaignsByPrimaryDcasListingRowBrandId"
      >
        <span>{campaign.brandUid}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 150, maxWidth: 150 }}
        className="text-trucate"
        data-testid="CampaignsByPrimaryDcasListingRowBrandName"
      >
        <span>{campaign.brandName || 'N/A'}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 120 }}
        data-testid="CampaignsByPrimaryDcasListingRowPrimaryDcaName"
      >
        {campaign.primaryDcaName || 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 120 }}
        data-testid="CampaignsByPrimaryDcasListingRowMnoList"
      >
        {campaign.mnoList && campaign.mnoList.length
          ? campaign.mnoList.map((mno) => mno.name).join(', ')
          : 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{}}
        data-testid="CampaignsByPrimaryDcasListingRowUsecase"
      >
        {campaign.usecase ? campaign.usecase : 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 85 }}
        data-testid="CampaignsByPrimaryDcasListingRowSharedOnDate"
      >
        {campaign.sharedOnDate
          ? convertTimeWithTimezone(campaign.sharedOnDate, 'MM/DD/YYYY')
          : '-'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 81 }}
        data-testid="CampaignsByPrimaryDcasListingRowCampaignStatus"
      >
        {remapTCRStatus(campaign.status)}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 230, paddingRight: 0 }}
        data-testid="CampaignsByPrimaryDcasListingRowSecondarySharingStatus"
      >
        <div className="campaign-status-wrapper flex-centered">
          {campaign.onProcessing ? (
            <CircularProgress size="20px" style={{ color: '#0091B3' }} />
          ) : (
            <React.Fragment>
              {statusIcon(campaign.secondarySharingStatus)}

              {campaign.secondarySharingStatus === 'PENDING' &&
                campaign.status !== 'EXPIRED' && (
                  <div className="action-buttons-wrapper">
                    <ul className="list-inline">
                      <li style={{ marginLeft: 0 }}>
                        <p>
                          <RoleGuard
                            feature="campaignByDcaList.acceptCampaign"
                            render={(hasPermission) => {
                              return (
                                <a
                                  data-testid="acceptButton"
                                  className={`primary-btn ${
                                    hasPermission ? '' : 'disabled'
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.handleActionClick(
                                      'accept',
                                      campaign.uid
                                    );
                                  }}
                                >
                                  Accept
                                </a>
                              );
                            }}
                          />
                        </p>
                      </li>
                      <li style={{ marginLeft: 4 }}>
                        <p>
                          <RoleGuard
                            feature="campaignByDcaList.declineCampaign"
                            render={(hasPermission) => {
                              return (
                                <a
                                  data-testid="declineButton"
                                  className={`secondary-btn ${
                                    hasPermission ? '' : 'disabled'
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.handleActionClick(
                                      'reject',
                                      campaign.uid,
                                      campaign
                                    );
                                  }}
                                >
                                  Decline
                                </a>
                              );
                            }}
                          />
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
            </React.Fragment>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
