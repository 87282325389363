import React, { useState, useEffect } from 'react';
import { AutomatedActionBtn } from './index';
import {
  globalGetService,
  globalPutService,
} from '../../../utils/globalApiServices';
import {
  Dialog,
  DialogContent,
  ClickAwayListener,
  List,
  ListItem,
  TextField,
} from '@material-ui/core';
import { ToolTip } from '../../../shared_elements';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';

export default function AddNewExceptionCsp(props) {
  const { open } = props;
  const [cspUid, setCspUid] = useState('');
  const [cspName, setCspName] = useState('');
  const [action, setAction] = useState('REVIEW');
  const [error, setError] = useState({
    cspName: '',
  });
  const [loader, setLoader] = useState(false);
  const [cspSuggestions, setCspSuggestions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [suggestionLoader, setSuggestionLoader] = useState(true);

  const fetchCspSuggestions = (query = {}) => {
    setSuggestionLoader(true);
    globalGetService('dca/csp/suggestions', { ...query, limit: 20 }).then(
      (response) => {
        if (response && response.status >= 200 && response.status < 300) {
          setSuggestionLoader(false);
          setCspSuggestions(response.data);
        }
      }
    );
  };
  useEffect(() => {
    setError({});
    setCspUid('');
    setCspName('');
    setAction('REVIEW');
    setLoader(false);
    setCspSuggestions([]);
    setAnchorEl(null);
    setSuggestionLoader(true);
  }, [open]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    let validateNewInput = {
      cspUid: cspUid ? '' : 'Please select csp',
    };
    if (
      Object.keys(validateNewInput).every((k) => validateNewInput[k] === '')
    ) {
      const formData = {
        action,
        cspUid,
      };
      globalPutService(
        `dca/primaryDcaCspRule?action=${formData.action}&cspUid=${formData.cspUid}`,
        {}
      ).then((response) => {
        setLoader(false);
        if (response.status >= 200 && response.status < 300) {
          props.fetchListOfExceptionalCsps();
          props.handleClose();
        }
      });
    } else {
      setLoader(false);
      setError(validateNewInput);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={props.handleClose}
      maxWidth="xs"
      fullWidth={true}
      className={`add-new-exception-csp default-dialog`}
      data-testid="addNewExceptionCsp"
      disableBackdropClick
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <h3 className="heading2 text-center title">
            <Box flexDirection="row" justifyContent="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faUserPlus} />
              </Box>
              <span>Exception for CSP</span>
            </Box>
          </h3>
          <div className="form-group-field suggestion-field">
            <TextField
              value={cspName}
              id=""
              data-testid="addNewExceptionCspNameInput"
              label="Type name here"
              error={error.cspUid ? true : false}
              fullWidth={true}
              inputProps={{ 'aria-label': '' }}
              onChange={(e) => {
                setAnchorEl(e.currentTarget);
                setCspName(e.target.value);
                setError({ cspUid: '' });
                if (e.target.value.length >= 2) {
                  fetchCspSuggestions({ prefix: e.target.value });
                } else {
                  setCspSuggestions([]);
                }
              }}
            />
            {error.cspUid ? (
              <h6 className="error-msg">{error.cspUid}</h6>
            ) : null}
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                position: 'absolute',
                right: '-10px',
                top: '28px',
              }}
            >
              <ToolTip
                title={`Please select the CSP and the action you wish to be automatically applied. Setting an automated action will affect all campaigns that are coming from the chosen CSP. The effect is limited to Campaigns for which you have been elected as a "Primary DCA" from CSPs.`}
              />
            </div>
            {cspSuggestions && Boolean(anchorEl) ? (
              <ClickAwayListener
                onClickAway={() => {
                  setCspName('');
                  setCspUid('');
                  setAnchorEl(null);
                }}
              >
                <div className="auto-suggestions-dropdown">
                  <List
                    className="listUnstyle"
                    data-testid="addNewExceptionCspNameList"
                  >
                    {cspSuggestions.length > 0 ? (
                      cspSuggestions.map((item, index) => (
                        <ListItem
                          key={index}
                          data-testid={`addNewExceptionCsp-${item.cspName}`}
                          onClick={() => {
                            setAnchorEl(null);
                            setCspName(item.cspName);
                            setCspUid(item.cspUid);
                          }}
                        >
                          {item.cspName}
                        </ListItem>
                      ))
                    ) : cspName && !suggestionLoader ? (
                      <ListItem
                        onClick={() => {
                          setCspName('');
                          setCspUid('');
                          setAnchorEl(null);
                        }}
                        className="noOptions"
                      >
                        {cspName && cspName.length < 2
                          ? 'Type at least 2 characters'
                          : 'No options available'}
                      </ListItem>
                    ) : (
                      <ListItem
                        onClick={() => {
                          setCspName('');
                          setCspUid('');
                          setAnchorEl(null);
                        }}
                        className="noOptions"
                      >
                        {cspName && cspName.length < 2
                          ? 'Type at least 2 characters'
                          : 'No options available'}
                      </ListItem>
                    )}
                  </List>
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
          <div className="select-automated-action">
            <ul className="list-inline">
              <li>
                <p className="supportText">AUTOMATED ACTION</p>
              </li>
              <li>
                <AutomatedActionBtn
                  action={action}
                  exception={true}
                  handleChange={(option) => setAction(option)}
                />
              </li>
            </ul>
          </div>
          <ul className="list-inline footer text-center">
            <li>
              <a
                className={`secondary-btn ${loader ? 'disabled' : ''}`}
                onClick={props.handleClose}
                data-testid="addNewExceptionCspCancelButtton"
              >
                Cancel
              </a>
            </li>
            <li className="form-group-field">
              <input
                data-testid="addNewExceptionCspSubmitButtton"
                type="submit"
                className={`primary-btn ${
                  loader || error.cspUid ? 'disabled' : ''
                }`}
                value="Save"
              />
            </li>
          </ul>
        </form>
      </DialogContent>
    </Dialog>
  );
}
