import Integrations from './containers/Integrations';
import { Role, RouteConfig } from '../../types';

const routes: RouteConfig[] = [
  {
    key: 'integrations',
    path: '/integrations',
    title: 'Integrations',
    component: Integrations,
    roles: [Role.User, Role.Manager],
  },
];

export default routes;
